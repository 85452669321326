<template>
  <div class="request-block">
    <BaseInfoCard title="Информация по заявке">
      <template v-slot:content>
        <div class="request-card">
          <div
            v-for="(item, index) in cardData"
            :key="index"
            class="request-info-line"
          >
            <div class="name">{{ item.text }}</div>
            <div class="value">{{ item.value }}</div>
          </div>
        </div>
      </template>
    </BaseInfoCard>
    <div class="list-block">
      <ModelList
        :is-loading="state.request.isLoadingRequest"
        :data="state.request.requestData.models"
        @clickCompare="clickCompare($event)"
      />
    </div>
    <BasePopUp
      className="start-search-modal"
      title="Поиск похожих моделей"
      ref="startSearchPopUpRef"
    >
      <template v-slot:content>
        <FormStartSearch
          :model-id="compareData.id"
          :model-uuid="compareData.uuid"
          @closePopup="togglePopup"
        />
      </template>
    </BasePopUp>
  </div>
</template>

<script src="./RequestData.ts" lang="ts"/>
<style src="./RequestData.scss" lang="scss" />
