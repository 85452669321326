import { defineComponent, ref } from 'vue';
import BaseIconButton from '@/components/BaseComponents/BaseIconButton/BaseIconButton.vue';

export default defineComponent({
  name: 'BaseInfoCard',

  components: {
    BaseIconButton
  },

  props: {
    title: {
      type: String,
      required: true
    }
  },

  emits: ['changeOpenCard'],

  setup(_, { emit }) {
    const isOpenCard = ref(true);

    const toggleCard = () => {
      isOpenCard.value = !isOpenCard.value;
      emit('changeOpenCard', isOpenCard.value);
    };

    return {
      isOpenCard,
      toggleCard
    };
  }
});
