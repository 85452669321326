<template>
  <div
    v-show="isOpenCard"
    class="search-card-block"
  >
    <div class="title">
      <span>{{ title }}</span>
      <div class="buttons-wrapper">
        <slot name="additional-buttons"></slot>
        <BaseIconButton
          tool-tip-text="Скрыть"
          @clicked="toggleCard"
        >
          <template v-slot:button-image>
            <i class="pi pi-minus-circle blue-icon"></i>
          </template>
        </BaseIconButton>
      </div>
    </div>
    <slot name="content"></slot>
  </div>
  <div
    class="open-button-wrapper"
    v-show="!isOpenCard"
  >
    <BaseIconButton
      tool-tip-text="Показать"
      @clicked="toggleCard"
    >
      <template v-slot:button-image>
        <i class="pi pi-plus-circle blue-icon"></i>
      </template>
    </BaseIconButton>
  </div>
</template>

<script src="./BaseInfoCard.ts" lang="ts"/>
<style src="./BaseInfoCard.scss" lang="scss" />
