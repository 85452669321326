import { computed, defineComponent } from 'vue';
import BaseInfoCard from '@/components/BaseComponents/BaseInfoCard/BaseInfoCard.vue';
import { useStore } from 'vuex';
import BaseAuthCardImg from '@/components/BaseComponents/BaseAuthCardImg/BaseAuthCardImg.vue';
import { ModelStatus } from '@/types/modelType';
import BaseTable from '@/components/BaseComponents/BaseTable/BaseTable.vue';
import ReportSearchTable from '@/components/ReportSearchTable/ReportSearchTable.vue';
import BasePaginator from '@/components/BaseComponents/BasePaginator/BasePaginator.vue';
import defaultPaginatorSettings from '@/dictionaries/defaultPaginatorSettings/defaultPaginatorSettings';
import BaseLoader from '@/components/BaseComponents/BaseLoader/BaseLoader.vue';
import ReportFilters from '@/components/PageRequest/components/Report/components/ReportFilters/ReportFilters.vue';
import type { TEventPaginator } from '@/types/eventTypes';
import { MODULES_NAMES } from '@/store/names/modules.name';
import { PAGINATOR_MUTATIONS_TYPE } from '@/store/names/mutations.names';

export default defineComponent({
  name: 'Report',

  components: {
    BaseInfoCard,
    BaseAuthCardImg,
    BaseTable,
    ReportSearchTable,
    BasePaginator,
    BaseLoader,
    ReportFilters
  },

  setup() {
    const { state, commit } = useStore();

    const modelsArr = computed(() => {
      return state.request.requestData.models;
    });

    const getImgClasses = (status: ModelStatus) => {
      return `img-wrapper ${status === ModelStatus.FAILED && 'failed-model'}
              ${status === ModelStatus.PROCESSING && 'processing-model'}`;
    };

    const unitsCount = computed(() => state.search?.reportUnits?.totalElements || 0);

    const rootStatistic = computed(() => state.search?.reportUnits?.statistics);

    const partCoincidenceCount = computed(() => {
      return (rootStatistic.value?.totalModelPart +
        rootStatistic.value?.totalPartModel +
        rootStatistic.value?.totalPartPart) || 0;
    });

    const modelCoincidenceCount = computed(() => {
      return rootStatistic.value?.totalModelModel || 0;
    });

    const modelsCount = computed(() => {
      return (rootStatistic.value?.totalModelModel +
        rootStatistic.value?.totalModelPart +
        rootStatistic.value?.totalPartModel +
        rootStatistic.value?.totalPartPart) || 0;
    });

    const onChangePagination = (event: TEventPaginator) => {
      commit(`${MODULES_NAMES.PAGINATOR}/${PAGINATOR_MUTATIONS_TYPE.SET_PAGINATOR}`, {
        size: event.rows,
        page: event.page
      });
    };

    return {
      onChangePagination,
      defaultPaginatorSettings,
      modelsCount,
      modelCoincidenceCount,
      partCoincidenceCount,
      unitsCount,
      modelsArr,
      state,
      getImgClasses
    };
  }
});
