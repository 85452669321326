<template>
  <div class="inputs-block">
    <BaseInputText
      class-name="request-input"
      name="search"
      icon="pi-search"
      placeholder="Поиск"
      :value="state.search.filters.query"
      @input="changeFilterQuery($event)"
      @reset="resetFilterQuery()"
      has-remove-icon
    />
    <BaseInputCalendar
      name="date"
      placeholder="Время добавления в отчёт"
      @changed="changeFilterDate($event)"
      :value="state.search.filters.date"
      has-remove-icon
    />
  </div>
</template>

<script src="./ReportFilters.ts" lang="ts"/>
<style src="./ReportFilters.scss" lang="scss"/>
