import { defineComponent, PropType } from 'vue';
import SceneBlock from '@/components/Model/PageModelView/components/SceneBlock/SceneBlock.vue';
import { useStore } from 'vuex';
import mainSceneIds from '@/components/Search/components/constants/mainScenesId.ts';
import compareSceneIds from '@/components/Search/components/constants/compareScene.ts';
import type { TModelData } from '@/types/modelType';

export default defineComponent({
  name: 'ModelComparePopUp',

  components: {
    SceneBlock
  },

  props: {
    mainData: {
      type: Object as PropType<TModelData>,
      required: true
    },
    compareData: {
      type: Object as PropType<TModelData>,
      required: true
    },
    comparePartUuid: {
      type: String
    },
    mainPartUuid: {
      type: String
    },
    isLoading: {
      type: Boolean,
      default: true
    }
  },

  setup() {
    const { state } = useStore();

    return {
      state,
      mainSceneIds,
      compareSceneIds
    };
  }
});
