<template>
  <BaseButton
    prime-icon="pi-arrow-left"
    outlined
    class-name="back-button"
    @clicked="backHistoryClick"
  />
</template>

<script src="./BackButton.ts" lang="ts"/>
<style src="./BackButton.scss" lang="scss"/>
