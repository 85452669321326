<template>
  <BaseTable
    :columns="columns"
    :rows="rows"
    ref="reportTableRef"
    :class-name="`report-table ${isForSearch && 'for-search'}`"
    :is-loading="isLoading"
  >
    <template #[`header${1}`]="{ data }">
      <div>
        <span>{{ data.title }}</span>
        <BaseButton
          v-if="!isForSearch && !state.search.reportData.isFinished && !isInternalUser(state.users.user)"
          label="Сформировать отчет"
          outlined
          @clicked="finishReport"
          :disabled="isDeletingUnit.isLoading || isFinishingReport"
        />
        <div
          v-if="state.search.reportData.isFinished"
          class="finished-report"
        >
          Отчет зафиксирован
        </div>
      </div>
    </template>
    <template #row="{ data, index }">
      <tr>
        <td class="img-cell main-cell">
          <div v-if="!isForSearch" class="cell-count">{{ (index + 1) + (pageNumber * pageSize) }}</div>
          <div class="label-wrapper">
            <ModelLabel :model-type-id="data.cells[0].modelFrom.registeredModelType.id"/>
            <div
              v-if="data.cells[0].modelFrom.variantId !== null"
              class="version-label">
              {{ data.cells[0].modelFrom.variantId }}
            </div>
          </div>
          <div class="main-img">
            <ComparedImages
              :model-data="data.cells[0].modelFrom"
              :part-data="data.cells[0]?.modelPartFrom"
            />
          </div>
        </td>
        <td class="img-cell">
          <div class="label-wrapper">
            <ModelLabel :model-type-id="data.cells[1].modelTo.registeredModelType.id"/>
            <div
              v-if="data.cells[1].modelTo.variantId !== null"
              class="version-label">
              {{ data.cells[1].modelTo.variantId }}
            </div>
          </div>
          <div class="compare-img">
            <ComparedImages
              :model-data="data.cells[1].modelTo"
              :part-data="data.cells[1]?.modelPartTo"
            />
          </div>
        </td>
        <td class="action-cell">
          <div class="action-cell-inner">
            <div class="request-block">
              <span class="gray-text">Номер заявки</span>
              <router-link
                @click="handleClickRequestLink($event, index)"
                class="request-link"
                target="_blank"
                :to="{ name: ROUTE_NAMES.PAGE_REQUEST, params: { id: data.cells[2].modelTo?.requestId }}"
              >
                {{ getModelValue(data.cells[2].modelTo.requestId) }}
              </router-link>
            </div>
            <div class="patient-block">
              <span class="gray-text">{{ getTypeNameByModelType(data.cells[2].modelTo.registeredModelType.id) }}</span>
              <span>{{ getModelValue(data.cells[2].modelTo.patentId) }}</span>
            </div>
            <template v-if="!isForSearch">
              <div class="patient-block">
                <span class="gray-text">Время добавления в отчет</span>
                <span>{{ createdAt(data.cells[2].createdAt) }}</span>
              </div>
              <div class="patient-block">
                <span class="gray-text">Эксперт</span>
                <span>{{ getModelValue(data.cells[2].createdBy.login) }}</span>
              </div>
            </template>
            <div class="buttons-wrapper">
              <BaseIconButton
                tool-tip-text="Сравнение моделей"
                @clicked="clickCompare(data.cells[2])"
              >
                <template v-slot:button-image>
                  <img class="small-picture" alt="img" src="@/assets/icons/compare.svg">
                </template>
              </BaseIconButton>
              <BaseIconButton
                tool-tip-text="Перейти к просмотру"
                is-target-blank
                :href="{
                name: ROUTE_NAMES.PAGE_MODEL_VIEW,
                params: { id: data.cells[1].modelTo?.modelUuid },
                query: { partUuid: data.cells[1]?.modelPartTo?.uuid }
              }"
              >
                <template v-slot:button-image>
                  <img alt="img" src="@/assets/icons/open_model.svg">
                </template>
              </BaseIconButton>
            </div>
          </div>
        </td>
        <td>
          <div class="info-cell">
            <div
              :class="`percent-label ui-title-medium model-${data.cells[3].modelTo.registeredModelType?.id}label`"
            >
              {{ getPercents(data.cells[3].distance) }}
            </div>
            <div
              class="additional-info-button"
              @click="toggleAddInfo(index)"
              v-if="isForSearch"
              v-tooltip.top="'О заявке'"
            >
              <i class="pi pi-info-circle"></i>
            </div>
            <div
              class="additional-info-button"
              @click="toggleAddTechInfo(index)"
              v-if="isForSearch"
              v-tooltip.top="'Техническая информация'"
            >
              <img alt="img" src="@/assets/icons/tech_info.svg"/>
            </div>
            <template v-if="!state.search.reportData.isFinished && !isInternalUser(state.users.user)">
              <div v-if="!isForSearch" class="delete-button"
                   @click="!isDeletingUnit.isLoading && deleteUnit(data.cells[3].id)">
                <i v-if="isDeletingUnit.isLoading && (isDeletingUnit.id === data.cells[3].id)"
                   class="pi pi-spin pi-spinner deleting-loader"></i>
                <i v-else class="pi pi-trash"></i>
                <span>Удалить из отчета</span>
              </div>
              <ReportCheckbox
                v-if="isForSearch &&
                      hasRmtsAccess(state.users.user, [data.cells[3].modelFrom.registeredModelType?.id]) &&
                      !isInternalUser(state.users.user)"
                :model-data="data.cells[3].modelFrom"
                :part-data="data.cells[3].modelPartFrom || {}"
                :compare-model-data="data.cells[3].modelTo"
                :compare-part-data="data.cells[3].modelPartTo || {}"
                :compare-percents="data.cells[3].distance"
                :is-added-to-report="data.cells[3].isAddedToReport"
                :report-unit-id="data.cells[3].reportUnitId"
                :index="index"
                :search-unit-id="data.cells[3].id"
              />
            </template>
          </div>
        </td>
      </tr>
    </template>
    <template #additional-info="{ data }">
      <div class="p-grid">
        <template
          v-for="(item, index) in additionalInfo(data.cells[3].modelTo)"
          :key="index+data.cells[3].id"
        >
          <div
            class="p-col-4 additional-info-block"
            v-if="!!item.value"
          >
            <div class="label">{{ item.label }}</div>
            <div class="value">{{ item.value }}</div>
          </div>
        </template>
      </div>
    </template>
    <template #additional-info-second="{ data }">
      <div class="p-grid">
        <template
          v-for="(item, index) in additionalTechInfo(data.cells[3].modelTo)"
          :key="index+data.cells[3].id"
        >
          <div
            class="p-col-4 additional-info-block"
            v-if="!!item.value"
          >
            <div class="label">{{ item.label }}</div>
            <div class="value">{{ item.value }}</div>
          </div>
        </template>
      </div>
    </template>
  </BaseTable>
  <!--  TODO подумать стоит ли вынести в родительский компонент -->
  <BasePopUp ref="comparePopUpRef" class-name="compare-pop-up">
    <template v-slot:content>
      <ModelComparePopUp
        :main-data="mainData"
        :main-part-uuid="mainPartUuid"
        :compare-data="compareData"
        :compare-part-uuid="comparePartUuid"
        :is-loading="false"
      />
    </template>
  </BasePopUp>
</template>

<script src="./ReportSearchTable.ts" lang="ts"/>
<style src="./ReportSearchTable.scss" lang="scss"/>
