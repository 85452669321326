<template>
  <div class="compared-image-wrapper">
    <router-link
      :to="{ name: ROUTE_NAMES.PAGE_MODEL_VIEW, params: { id: modelData.modelUuid }}"
      target="_blank"
      v-show="isModelPreview"
      class="model-preview link-img"
    >
      <img :src="getAuthImageForCard(modelData.id)" alt="img"/>
    </router-link>
    <router-link
      :to="{ name: ROUTE_NAMES.PAGE_MODEL_VIEW, params: { id: modelData.modelUuid }}"
      target="_blank"
      v-if="partData?.id"
      v-show="!isModelPreview"
      class="part-preview link-img"
    >
      <img :src="getAuthImageForCard(modelData.id, partData.uuid)" alt="img"/>
    </router-link>
    <div
      v-if="partData?.id"
      class="preview-controller"
    >
      <BaseButton
        @clicked="setPreview(false)"
        label="Деталь"
        :outlined="isModelPreview"
      />
      <BaseButton
        @clicked="setPreview(true)"
        label="Модель"
        :outlined="!isModelPreview"
      />
    </div>
  </div>
</template>

<script src="./ComparedImages.ts" lang="ts"/>
<style src="./ComparedImages.scss" lang="scss"/>
