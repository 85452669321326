import { defineComponent } from 'vue';
import BaseInputCalendar from '@/components/BaseComponents/BaseInputCalendar/BaseInputCalendar.vue';
import BaseInputText from '@/components/BaseComponents/BaseInputText/BaseInputText.vue';
import type { HTMLInputEvent } from '@/types/eventTypes';
import { useStore } from 'vuex';
import { MODULES_NAMES } from '@/store/names/modules.name';
import moment from 'moment';
import type { TDateFilters, TQueryFilters } from '@/store/modules/models/state';
import BaseButton from '@/components/BaseComponents/BaseButton/BaseButton.vue';
import { setBeginOfDay, setEndOfDay } from '@/utils/setDayTime';
import { SEARCH_MUTATIONS_TYPE } from '@/store/names/mutations.names';

export default defineComponent({
  name: 'ReportFilters',
  components: {
    BaseInputCalendar,
    BaseInputText,
    BaseButton
  },

  setup() {
    const { state, dispatch } = useStore();

    const commitChanges = (type: string, value: TDateFilters | TQueryFilters) => {
      const payload = { ...state.search.filters };
      payload[type] = value;
      dispatch(`${MODULES_NAMES.SEARCH}/${SEARCH_MUTATIONS_TYPE.SET_REPORT_FILTERS}`, payload);
    };

    const changeFilterDate = (value: TDateFilters) => {
      if (value === null) {
        return commitChanges('date', null);
      }
      const [firstDate, secondDate] = [value[0], value[1]];
      if (value[1] !== null) {
        return commitChanges('date', [
          moment(setBeginOfDay(new Date(firstDate))).toISOString(),
          moment(setEndOfDay(new Date(secondDate))).toISOString()
        ]);
      }
    };

    const changeFilterQuery = (event: HTMLInputEvent) => {
      commitChanges('query', event.target.value);
    };

    const resetFilterQuery = () => {
      commitChanges('query', '');
    };

    return {
      changeFilterQuery,
      changeFilterDate,
      resetFilterQuery,
      state
    };
  }
});
