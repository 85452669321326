import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import BaseButton from '@/components/BaseComponents/BaseButton/BaseButton.vue';

export default defineComponent({
  name: 'BackButton',

  components: {
    BaseButton
  },

  setup() {
    const router = useRouter();

    const backHistoryClick = () => {
      router.go(-1);
    };

    return {
      backHistoryClick
    };
  }
});
