<template>
  <InfoHeadForPage
    :page-name="`Заявка ${state.request.requestData.requestId || ''}`"
    :has-bottom-block="false"
  >
    <template v-slot:pre-name-block>
      <BackButton/>
    </template>
    <template v-slot:content-top>
      <div class="request-info-block">
        <BaseButton
          label="Модели"
          :outlined="!isShowRequest"
          @clicked="setShowRequest(true)"
        />
        <BaseButton
          label="Отчет по заявке"
          :outlined="isShowRequest"
          @clicked="setShowRequest(false)"
        />
      </div>
    </template>
  </InfoHeadForPage>
  <RequestData v-show="isShowRequest" />
  <Report v-show="!isShowRequest" />
</template>

<script src="./PageRequest.ts" lang="ts"/>
<style src="./PageRequest.scss" lang="scss"/>
