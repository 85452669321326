<template>
  <div class="report-check-block">
    <BaseCheckbox
      :value="modelData.modelUuid"
      :name="modelData.modelUuid + index"
      :is-checked="isChecked"
      @clicked="toggleChecked"
      :disabled="state.search.isPostingReportUnit"
    />
    <span class="text-black">
      {{ isChecked ? 'В отчете' : 'Добавить в отчет' }}
    </span>
    <i v-show="isPostingUnit" class="pi pi-spin pi-spinner unit-loader" ></i>
  </div>
</template>

<script src="./ReportCheckbox.ts" lang="ts"/>
<style src="./ReportCheckbox.scss" lang="scss"/>
