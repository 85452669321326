import { defineComponent, PropType, ref } from 'vue';
import BaseButton from '@/components/BaseComponents/BaseButton/BaseButton.vue';
import { getAuthImage } from '@/utils/getAuthImage';
import { getModelImageUrl, getModelPartImageUrl } from '@/components/Model/utils/getImageUrl';
import type { TModelData } from '@/types/modelType';
import ROUTE_NAMES from '@/router/route.names.json';

export default defineComponent({
  name: 'ComparedImages',

  components: {
    BaseButton
  },

  props: {
    modelData: {
      type: Object as PropType<TModelData>,
      required: true
    },
    partData: {
      type: Object as PropType<TModelData>
    }
  },

  setup() {
    const isModelPreview = ref(true);

    const getAuthImageForCard = (modelId: number, partUuid?: string) => {
      return partUuid ? getAuthImage(getModelPartImageUrl(modelId, partUuid)) : getAuthImage(getModelImageUrl(modelId));
    };

    const setPreview = (bool: boolean) => {
      isModelPreview.value = bool;
    };

    return {
      isModelPreview,
      getAuthImageForCard,
      setPreview,
      ROUTE_NAMES
    };
  }
});
