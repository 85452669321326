<template>
  <div class="scene-pop-up-wrapper">
    <div class="main-model">
      <div class="title-block">Исходная модель</div>
      <div class="request-block">
        <span class="name">Номер заявки</span>
        <span class="value">{{ mainData.requestId }}</span>
      </div>
      <SceneBlock
        :data="mainData"
        :scenes-ids="mainSceneIds"
        :is-loading="isLoading"
        :compare-part-uuid="mainPartUuid"
        is-for-compare
      />
    </div>
    <div class="compare-model">
      <div class="title-block">Похожая модель</div>
      <div class="request-block">
        <span class="name">Номер заявки</span>
        <span class="value">{{ compareData.requestId }}</span>
      </div>
      <SceneBlock
        :data="compareData"
        :scenes-ids="compareSceneIds"
        :is-loading="isLoading"
        :compare-part-uuid="comparePartUuid"
        is-for-compare
      />
    </div>
  </div>
</template>

<script src="./ModelComparePopUp.ts" lang="ts"/>
<style src="./ModelComparePopUp.scss" lang="scss"/>
