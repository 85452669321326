import { computed, defineComponent, ref } from 'vue';
import BaseTable from '@/components/BaseComponents/BaseTable/BaseTable.vue';
import { useStore } from 'vuex';
import BaseButton from '@/components/BaseComponents/BaseButton/BaseButton.vue';
import { MODULES_NAMES } from '@/store/names/modules.name';
import { SEARCH_ACTIONS_TYPE } from '@/store/names/action.name';
import ComparedImages from '@/components/ComparedImages/ComparedImages.vue';
import appendToastMessage from '@/utils/appendToastMessage';
import FORM_DICTIONARY from '@/dictionaries/validation/dictionary.json';
import { createdAt } from '@/components/Model/ModelCards/utils/createdAt';
import ModelComparePopUp from '@/components/Search/components/ModelComparePopUp/ModelComparePopUp.vue';
import BasePopUp from '@/components/BaseComponents/BasePopUp/BasePopUp.vue';
import type { TSearchedData, TReportUnitContent } from '@/store/modules/search/state';
import { RegisteredModelTypes, TModelData } from '@/types/modelType';
import ROUTE_NAMES from '@/router/route.names.json';
import BaseIconButton from '@/components/BaseComponents/BaseIconButton/BaseIconButton.vue';
import ReportCheckbox from '@/components/Search/components/ReportCheckbox/ReportCheckbox.vue';
import ModelLabel from '@/components/Model/ModelLabel/ModelLabel.vue';
import { getModelValue } from '@/components/Model/ModelCards/utils/getModelValue';
import { getMkpoClassGrandParent, getMkpoClassParent, getMkpoName } from '@/utils/getMkpoClasses';
import { getTypeNameByModelType } from '@/utils/getNameByModelType';
import { useRouter } from 'vue-router';
import { checkPermissionsForLink } from '@/utils/checkPermissionsForLink';
import { hasRmtsAccess, isInternalUser, isAdmin } from '@/utils/rolesUtils';

export default defineComponent({
  name: 'ReportSearchTable',

  components: {
    BaseTable,
    ComparedImages,
    BaseButton,
    BasePopUp,
    ModelComparePopUp,
    BaseIconButton,
    ReportCheckbox,
    ModelLabel
  },

  props: {
    isForSearch: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean
    },
    pageNumber: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 1
    }
  },

  setup(props) {
    const { state, dispatch } = useStore();

    const columns = computed(() => {
      return [{ title: 'Исходная модель', colspan: 1 }, { title: 'Похожая модель', colspan: 3 }];
    });

    const rows = computed(() => {
      if (!props.isForSearch) {
        return state.search?.reportUnits?.content ? state.search?.reportUnits?.content?.map((item: TReportUnitContent) => {
          return { cells: [item, item, item, item] };
        }) : [];
      } else {
        return state.search.searchResult?.content?.length ? state.search.searchResult.content.map((item: TSearchedData) => {
          return { cells: [item, item, item, item] };
        }) : [];
      }
    });

    const getPercents = (number: number) => {
      return (number <= 1) ? (number * 100).toFixed(0) : number;
    };

    const getReport = () => {
      dispatch(`${MODULES_NAMES.SEARCH}/${SEARCH_ACTIONS_TYPE.DOWNLOAD_REPORT}`, {
        fileName: `report_for_request_${state.search.reportData.request.requestId}`,
        reportId: state.search.reportData.id
      });
    };

    const isFinishingReport = ref(false);
    const finishReport = async() => {
      isFinishingReport.value = true;
      try {
        await dispatch(`${MODULES_NAMES.SEARCH}/${SEARCH_ACTIONS_TYPE.FINISH_REPORT}`, state.search.reportData.id);
      } catch (err) {
        appendToastMessage(FORM_DICTIONARY.SERVER_ERROR);
      } finally {
        isFinishingReport.value = false;
      }
    };

    const isDeletingUnit = ref({ isLoading: false, id: 0 });
    const deleteUnit = async(id: number) => {
      isDeletingUnit.value = { isLoading: true, id: id };
      try {
        await dispatch(`${MODULES_NAMES.SEARCH}/${SEARCH_ACTIONS_TYPE.DELETE_UNIT_FROM_REPORT}`, {
          reportId: state.search.reportData.id,
          unitId: id
        });
      } catch (err) {
        console.error(err);
        appendToastMessage(FORM_DICTIONARY.SERVER_ERROR);
      } finally {
        isDeletingUnit.value = { isLoading: false, id: id };
      }
    };

    const comparePopUpRef = ref();
    const handleOpenPopUp = () => {
      comparePopUpRef.value.handleModal();
    };

    const mainData = ref<TModelData>({});
    const compareData = ref<TModelData>({});
    const comparePartUuid = ref();
    const mainPartUuid = ref();
    const clickCompare = (activeItem: TSearchedData) => {
      let compareModelData: TSearchedData;
      if (props.isForSearch) {
        compareModelData = state.search.searchResult.content.find((item: TSearchedData) => {
          return item.modelTo.id === activeItem.modelTo.id;
        });
      } else {
        compareModelData = state.search.reportUnits.content.find((item: TSearchedData) => {
          return item.modelTo.id === activeItem.modelTo.id;
        });
      }
      if (activeItem.modelPartFrom?.uuid) {
        mainPartUuid.value = activeItem.modelPartFrom?.uuid;
      } else {
        mainPartUuid.value = undefined;
      }
      if (activeItem.modelPartTo?.uuid) {
        comparePartUuid.value = activeItem.modelPartTo.uuid;
      } else {
        comparePartUuid.value = undefined;
      }
      mainData.value = activeItem.modelFrom;
      compareData.value = compareModelData.modelTo;
      handleOpenPopUp();
    };

    const reportTableRef = ref();

    const toggleAddInfo = (index: number) => {
      reportTableRef.value.isVisibleSecondBlock[index] && reportTableRef.value.toggleVisibleSecondBlock(index);
      reportTableRef.value.toggleVisible(index);
    };

    const toggleAddTechInfo = (index: number) => {
      reportTableRef.value.isVisibleBlock[index] && reportTableRef.value.toggleVisible(index);
      reportTableRef.value.toggleVisibleSecondBlock(index);
    };

    const additionalInfo = (data: TModelData) => {
      return data.registeredModelType?.id === RegisteredModelTypes.INDUSTRIAL_MODEL
        ? [
          { label: 'Класс МКПО', value: (data.mkpoClassGrandParent && getMkpoClassGrandParent(data)) },
          { label: 'Подкласс МКПО', value: (data.mkpoClassParent && getMkpoClassParent(data)) },
          { label: 'Наименование', value: (data.mkpoClass && getMkpoName(data)) },
          { label: 'Номер заявки', value: (data.requestId && getModelValue(data.requestId)) },
          {
            label: getTypeNameByModelType(state.models?.modelsData?.content[0]?.registeredModelType?.id),
            value: (data.patentId && getModelValue(data.patentId))
          }
        ]
        : [
          { label: 'Номер заявки', value: (data.requestId && getModelValue(data.requestId)) },
          {
            label: getTypeNameByModelType(state.models?.modelsData?.content[0]?.registeredModelType?.id),
            value: (data.patentId && getModelValue(data.patentId))
          }
        ];
    };

    const additionalTechInfo = (data: TModelData) => {
      const techData = [
        { label: 'Дата поступления модели', value: `${createdAt(data?.createdAt)} МСК` },
        { label: 'Пользователь, загрузивший модель', value: data?.author?.login },
        { label: 'Имя файла', value: (data.fileName) }
      ];
      if (isAdmin(state.users.user) || isInternalUser(state.users.user)) {
        techData.push({
          label: 'Универсальный уникальный идентификатор',
          value: (data.modelUuid)
        });
      }
      return techData;
    };

    const { resolve } = useRouter();
    const handleClickRequestLink = (event: MouseEvent, index: number) => {
      checkPermissionsForLink(
        event,
        state.users?.user,
        [rows.value[index].cells[0].modelTo.registeredModelType!.id],
        () => {
          const routeData = resolve({ name: ROUTE_NAMES.PAGE_REQUEST, params: { id: rows.value[index].cells[0].modelTo.requestId! } });
          window.open(routeData.href, '_blank');
        },
        () => {
          const routeData = resolve({ name: ROUTE_NAMES.PAGE_REQUEST, params: { id: rows.value[index].cells[0].modelTo.requestId! } });
          window.open(routeData.href, '_blank');
        },
        () => { appendToastMessage(FORM_DICTIONARY.NO_PERMISSIONS); }
      );
    };

    return {
      isInternalUser,
      hasRmtsAccess,
      handleClickRequestLink,
      toggleAddTechInfo,
      additionalTechInfo,
      getTypeNameByModelType,
      createdAt,
      isFinishingReport,
      finishReport,
      additionalInfo,
      getModelValue,
      isDeletingUnit,
      getPercents,
      ROUTE_NAMES,
      mainData,
      compareData,
      comparePartUuid,
      mainPartUuid,
      clickCompare,
      comparePopUpRef,
      deleteUnit,
      getReport,
      toggleAddInfo,
      reportTableRef,
      columns,
      rows,
      state
    };
  }
});
