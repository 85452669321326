<template>
  <div class="request-block">
    <BaseInfoCard title="Модели">
      <template v-slot:content>
        <div class="request-info">
          <div class="reports-models">
            <div
              v-for="(item, index) in modelsArr"
              :class="getImgClasses(item.status)"
              :key="index"
            >
              <BaseAuthCardImg :data="item"/>
            </div>
          </div>
          <div class="title">Информация о результатах поиска:</div>
          <div class="additional-info">
            <span class="name">Всего похожих</span>
            <span class="value">{{ modelsCount }}</span>
          </div>
          <div class="additional-info">
            <span class="name">По моделям</span>
            <span class="value">{{ modelCoincidenceCount }}</span>
          </div>
          <div class="additional-info">
            <span class="name">По деталям</span>
            <span class="value">{{ partCoincidenceCount }}</span>
          </div>
        </div>
      </template>
    </BaseInfoCard>
    <BaseLoader v-if="state.search.isLoadingReport" />
    <div class="list-block">
      <div class="p-grid">
        <div class="p-col-12">
          <div class="report-filter-wrapper">
            <ReportFilters />
          </div>
        </div>
      </div>
      <ReportSearchTable
        :is-loading="state.search.isLoadingReportUnits && !state.search.isLoadingReport"
        :page-number="state.paginator.paginator.page"
        :page-size="state.paginator.paginator.size"
      />
      <div
        v-if="!state.search?.reportUnits?.content?.length && !state.search.isLoadingReportUnits && modelsCount"
        class="empty-report-filters"
      >
        <img alt="not found" src="@/assets/images/no_results.svg"/>
        <div class="ui-title-large">моделей по вашему запросу не найдено</div>
        <div>
          <div class="bold-text">
            Рекомендации:
          </div>
          <div class="text">
            1. Убедитесь, что номер заявки или номер ОД введены правильно<br>
            2. Попробуйте использовать для отображения другой период загрузки
          </div>
        </div>
      </div>
      <div
        v-if="!state.search?.reportUnits?.content?.length && !state.search.isLoadingReportUnits && !modelsCount"
        class="ui-title-large empty-report"
      >
        Пустой отчет
      </div>
      <BasePaginator
        :total-records="unitsCount"
        @changed="onChangePagination($event)"
        :rows-per-page-options="defaultPaginatorSettings.selectSize"
        :size-rows="state.paginator.paginator.size"
        :first-page-prop="state.paginator.paginator.page"
        :total-pages="state.search?.reportUnits?.totalPages"
      />
    </div>
  </div>
</template>

<script src="./Report.ts" lang="ts"/>
<style src="./Report.scss" lang="scss" />
