import { computed, defineComponent, ref } from 'vue';
import ModelList from '@/components/Model/ModelList/ModelList.vue';
import BaseInfoCard from '@/components/BaseComponents/BaseInfoCard/BaseInfoCard.vue';
import { useStore } from 'vuex';
import BasePopUp from '@/components/BaseComponents/BasePopUp/BasePopUp.vue';
import FormStartSearch from '@/components/Forms/FormStartSearch/FormStartSearch.vue';

export default defineComponent({
  name: 'RequestData',

  components: {
    ModelList,
    BaseInfoCard,
    BasePopUp,
    FormStartSearch
  },

  setup() {
    const { state } = useStore();

    const cardData = computed(() => {
      return [
        {
          text: 'Номер заявки',
          value: state.request.requestData.requestId
        },
        {
          text: 'Идентификатор заявки',
          value: state.request.requestData.id
        },
        {
          text: 'Число моделей',
          value: state.request?.requestData?.models?.length
        }
        // TODO
        // {
        //   text: 'Пользователь, загрузивший модель',
        //   value: state.request?.requestData?.requester || 'Нет'
        // }
      ];
    });

    const startSearchPopUpRef = ref();
    const togglePopup = () => {
      startSearchPopUpRef.value.handleModal();
    };

    const compareData = ref({ id: '', uuid: '' });
    const clickCompare = (item: { id: string; uuid: string }) => {
      compareData.value = {
        id: item.id,
        uuid: item.uuid
      };
      togglePopup();
    };

    return {
      togglePopup,
      compareData,
      startSearchPopUpRef,
      cardData,
      clickCompare,
      state
    };
  }
});
