import { defineComponent, onMounted, onUnmounted, ref, watch } from 'vue';
import InfoHeadForPage from '@/components/InfoHeadForPage/InfoHeadForPage.vue';
import { useRoute, useRouter } from 'vue-router';
import RequestData from '@/components/PageRequest/components/RequestData/RequestData.vue';
import Report from '@/components/PageRequest/components/Report/Report.vue';
import BaseButton from '@/components/BaseComponents/BaseButton/BaseButton.vue';
import BackButton from '@/components/BackButton/BackButton.vue';
import { useStore } from 'vuex';
import { REQUEST_ACTIONS_TYPE, SEARCH_ACTIONS_TYPE } from '@/store/names/action.name';
import { MODULES_NAMES } from '@/store/names/modules.name';
import { REQUEST_MUTATIONS_TYPE, SEARCH_MUTATIONS_TYPE } from '@/store/names/mutations.names';
import BaseLoader from '@/components/BaseComponents/BaseLoader/BaseLoader.vue';
import _ from 'lodash';
import setPaginatorAndFiltersFromQuery from '@/components/Model/utils/setPaginatorAndFiltersFromQuery';
import { switchError } from '@/services/utils/switchError';
import FORM_DICTIONARY from '@/dictionaries/validation/dictionary.json';

export default defineComponent({
  name: 'PageRequest',

  components: {
    InfoHeadForPage,
    RequestData,
    Report,
    BaseButton,
    BackButton,
    BaseLoader
  },

  setup() {
    const { params } = useRoute();
    const { dispatch, state, commit } = useStore();
    const isShowRequest = ref(true);
    const { query } = useRoute();
    const { replace } = useRouter();

    const setShowRequest = (boolean: boolean) => {
      isShowRequest.value = boolean;
    };

    onMounted(async() => {
      if (query.isReport) {
        setShowRequest(false);
        setPaginatorAndFiltersFromQuery(query);
        replace({ query: {} });
      }
      try {
        await dispatch(`${MODULES_NAMES.REQUEST}/${REQUEST_ACTIONS_TYPE.GET_REQUEST_DATA}`, params.id);
        await dispatch(`${MODULES_NAMES.SEARCH}/${SEARCH_ACTIONS_TYPE.GET_REPORT_DATA}`, state.request.requestData.requestId);
        await dispatch(`${MODULES_NAMES.SEARCH}/${SEARCH_ACTIONS_TYPE.GET_REPORT_UNITS}`, state.search.reportData.id);
      } catch (err) {
        switchError(err, FORM_DICTIONARY.REQUEST_NOT_FOUND);
      }
    });

    onUnmounted(() => {
      commit(`${MODULES_NAMES.REQUEST}/${REQUEST_MUTATIONS_TYPE.SET_REQUEST_DATA}`, {});
      commit(`${MODULES_NAMES.SEARCH}/${SEARCH_MUTATIONS_TYPE.SET_REPORT_DATA}`, {});
      commit(`${MODULES_NAMES.SEARCH}/${SEARCH_MUTATIONS_TYPE.SET_REPORT_UNITS}`, {});
    });

    const getReportUnits = async() => {
      try {
        await dispatch(`${MODULES_NAMES.SEARCH}/${SEARCH_ACTIONS_TYPE.GET_REPORT_UNITS}`, state.search.reportData.id);
      } catch (err) {
        switchError(err, FORM_DICTIONARY.REQUEST_NOT_FOUND);
      }
    };

    const debouncedGetModelsList = _.debounce(getReportUnits, 300);

    watch(() => state.paginator.paginator, () => {
      debouncedGetModelsList();
    });

    return {
      state,
      isShowRequest,
      setShowRequest
    };
  }
});
