import { computed, defineComponent, PropType, ref, onMounted } from 'vue';
import ROUTE_NAMES from '@/router/route.names.json';
import type { TModelData } from '@/types/modelType';
import { getModelValue } from '@/components/Model/ModelCards/utils/getModelValue';
import BaseCheckbox from '@/components/BaseComponents/BaseCheckbox/BaseCheckbox.vue';
import { useStore } from 'vuex';
import appendToastMessage from '@/utils/appendToastMessage';
import FORM_DICTIONARY from '@/dictionaries/validation/dictionary.json';
import type { TReportUnit } from '@/types/reportUnit';
import { MODULES_NAMES } from '@/store/names/modules.name';
import { SEARCH_ACTIONS_TYPE } from '@/store/names/action.name';

export default defineComponent({
  name: 'ReportCheckbox',

  components: {
    BaseCheckbox
  },

  props: {
    modelData: {
      type: Object as PropType<TModelData>,
      required: true
    },
    partData: {
      type: Object as PropType<TModelData>,
      required: true
    },
    compareModelData: {
      type: Object as PropType<TModelData>,
      required: true
    },
    comparePartData: {
      type: Object as PropType<TModelData>,
      required: true
    },
    comparePercents: {
      type: Number,
      required: true
    },
    isAddedToReport: {
      type: Boolean,
      required: true
    },
    reportUnitId: {
      type: Number
    },
    index: {
      type: Number,
      default: 0
    },
    searchUnitId: {
      type: Number,
      required: true
    }
  },

  setup(props) {
    const isChecked = ref(false);
    const { state, dispatch } = useStore();
    const isPostingUnit = ref(false);

    onMounted(() => {
      isChecked.value = props.isAddedToReport;
    });

    const percents = computed(() => {
      return (props.comparePercents * 100).toFixed(0);
    });

    const toggleChecked = async() => {
      isPostingUnit.value = true;
      if (!isChecked.value) {
        const unit: TReportUnit = {
          distance: +percents.value,
          modelFromId: props.modelData.id!,
          modelToId: props.compareModelData.id!
        };
        if (props.partData?.id) {
          unit.modelPartFromId = props.partData.id;
        }
        if (props.comparePartData?.id) {
          unit.modelPartToId = props.comparePartData.id;
        }
        try {
          await dispatch(`${MODULES_NAMES.SEARCH}/${SEARCH_ACTIONS_TYPE.ADD_UNIT_TO_REPORT}`, {
            modelToUuid: props.compareModelData.modelUuid,
            reportId: state.search.reportData.id,
            params: unit,
            searchUnitId: props.searchUnitId
          });
          isChecked.value = !isChecked.value;
        } catch (err) {
          appendToastMessage(FORM_DICTIONARY.SERVER_ERROR);
        }
      } else {
        try {
          await dispatch(`${MODULES_NAMES.SEARCH}/${SEARCH_ACTIONS_TYPE.DELETE_UNIT_FROM_REPORT}`, {
            reportId: state.search.reportData.id,
            unitId: props.reportUnitId,
            searchUnitId: props.searchUnitId
          });
          isChecked.value = !isChecked.value;
        } catch (err) {
          appendToastMessage(FORM_DICTIONARY.SERVER_ERROR);
        }
      }
      isPostingUnit.value = false;
    };

    return {
      state,
      isPostingUnit,
      percents,
      getModelValue,
      ROUTE_NAMES,
      isChecked,
      toggleChecked
    };
  }
});
